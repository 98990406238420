import { navigate } from "gatsby-link"
import * as React from "react"
import { anmeldungRoutesAbs } from "../components/anmeldung/anmeldungRouter"
import { extendUtm } from "../state/anmeldung/anmeldungSlice"
import { useAppDispatch } from "../state/createStore"
import queryString from "query-string"
interface Props {
  location: { search: string }
}

const FahrzeugscheinReUpload = (props: Props) => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (props.location.search) {
      dispatch(extendUtm(props?.location?.search))
      const utmParams = queryString.parse(props.location.search)
      if (utmParams.dealId && typeof utmParams.dealId === "string") {
        navigate(
          anmeldungRoutesAbs.fahrzeugscheinReUpload +
            "?dealId=" +
            utmParams.dealId
        )
      } else {
        navigate("/")
      }
    }
  }, [])
  return <>Bitte warten</>
}

export default FahrzeugscheinReUpload
